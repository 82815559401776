import type { Ref } from "vue";
import { useAddToCart, useSideMenuModal } from "#imports";
import type { Schemas } from "#shopware";

interface ExtraCartOptions {
  triggerSideCart?: boolean;
}

const defaultExtraCartOptions: ExtraCartOptions = {
  triggerSideCart: true,
};

/**
 * Extended `useAddToCartWithSideEffects` composable based on `useAddToCart` to be able to trigger some side effects
 * @public
 * @category Cart & Checkout Side Effects
 */
export function useAddToCartWithSideEffects(product: Ref<Schemas["Product"]>) {
  const _useAddToCart = useAddToCart(product);
  const sideMenuController = useSideMenuModal();

  return {
    ..._useAddToCart,
    addToCart: async (options: ExtraCartOptions = defaultExtraCartOptions) => {
      const cartResult = await _useAddToCart.addToCart();

      if (options.triggerSideCart) {
        sideMenuController.open();
      }

      return cartResult;
    },
  };
}
